import React, { FC, useState } from 'react'
import styles from './ProductsSlider.module.scss'
import cn from 'classnames'
import Link from 'next/link'
import { ProductDetails } from '../../../interfaces/CartContent'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import { ProductCard } from '../../common'

type MyProps = {
  productList: ProductDetails[]
  setShopifyCart: any
  cartContent: any
  breadCrumbsGoal?: { title: string; id: string }
  breadCrumbsCategory?: string
  isWebView?: boolean
  removeProduct?: (value: string) => void
  reconciliation?: boolean
  containerScaling?: string
  coefficientDiscount?: number
  errorPage?: boolean
}

const ProductsSlider: FC<MyProps> = ({
  productList,
  setShopifyCart,
  cartContent,
  breadCrumbsGoal,
  breadCrumbsCategory,
  removeProduct,
  reconciliation,
  containerScaling,
  coefficientDiscount,
  errorPage
}) => {
  const [paginationRef, setPaginationRef] = useState<HTMLElement | null>(null)

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const [prev, setPrev] = useState(false)

  return (
    <section className={cn(styles.sectionMain)}>
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionMainHeader}>
          {errorPage
              ? null
              : <div className={styles.sectionMainText}>Popular with membership</div>
          }
          {errorPage
              ? <div className={styles.sectionMainHeadline}>Popular products</div>
              : <div className={styles.sectionMainHeadline}>Meet our products</div>
          }
        </div>
        <div className={styles.sectionMainBody}>
          <div className={styles.slider}>
            <Swiper
              className={styles.sectionCarousel}
              spaceBetween={20}
              modules={[Navigation, Pagination]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                },
                // when window width is >= 860px
                860: {
                  slidesPerView: 3,
                },
              }}
              pagination={{
                el: paginationRef,
                clickable: true,
                bulletClass: styles.sectionCarouselBullet,
                bulletActiveClass: cn(styles.sectionCarouselBulletActive),
              }}
              loop
              slidesPerView={3}
              navigation={{ prevEl, nextEl }}
            >
              {productList.map((product) => {
                return (
                  <SwiperSlide
                    key={product.id}
                    className={styles.sectionCarouseItem}
                  >
                    <ProductCard
                      key={product.id}
                      data={product}
                      quantity={cartContent[product.frontVariantId]?.quantity}
                      updateShopifyCart={setShopifyCart(
                        product.frontVariantId,
                        product.variantId
                      )}
                      icons={product.collections.filter(
                        (el) => el.type === 'goal'
                      )}
                      breadCrumbGoal={breadCrumbsGoal}
                      breadCrumbCategory={breadCrumbsCategory}
                      reconciliation={reconciliation}
                      removeProduct={() =>
                        removeProduct && removeProduct(product.frontVariantId)
                      }
                      coefficientDiscount={coefficientDiscount}
                      styleSlider={true}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <div
              className={styles.sectionCarouselNext}
              ref={(node) => setNextEl(node)}
              onClick={() => setPrev(true)}
            ></div>
            <div
              className={
                prev
                  ? styles.sectionCarouselPrev
                  : cn(styles.sectionCarouselPrev, styles.hidden)
              }
              ref={(node) => setPrevEl(node)}
            ></div>
            <div
              ref={(node) => setPaginationRef(node)}
              className={cn(styles.sectionCarouselPagination, {[styles.paginationError]:errorPage})}
            >
              {productList.map((el, idx) => (
                <span key={idx} className={styles.sectionCarouselBullet}></span>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.sectionMainFooter}>
          {errorPage ? <Link href="https://shop.vitaliv.us/collections/all">
                <a className={cn(styles.sectionMainMore, styles.buttonArrow, containerScaling)}
                   href="https://shop.vitaliv.us/collections/all">
                  See all supplements
                </a>
              </Link> :
              <Link href="https://shop.vitaliv.us/collections/all">
                <a className={cn(styles.sectionMainMore, styles.buttonArrow)}
                   href="https://shop.vitaliv.us/collections/all">
                  See all supplements
                </a>
              </Link>
          }
        </div>
      </div>
    </section>
  )
}

export default ProductsSlider
